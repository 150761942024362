import React from 'react';

import { Link } from 'navigation/Link';

import * as styles from './styles';

type Props = {
  opportunityName: string;
  accountExecutive: string;
  opportunityId: string | undefined;
};

const SummaryInsightHeader: React.FC<Props> = ({
  opportunityName,
  accountExecutive,
  opportunityId,
}) => (
  <div className={styles.summaryInsightHeaderContainer}>
    <div className="header-opportunity">
      <span>Opportunity:</span>

      {opportunityName && opportunityId ? (
        <Link
          url={{
            scheme: '/opportunities/preview/:dealId',
            params: { dealId: opportunityId },
          }}
        >
          <span className="link">{opportunityName}</span>
        </Link>
      ) : (
        <span>{opportunityName || '-'}</span>
      )}
    </div>

    <div className="header-ae">
      <span>AE:</span>
      <span>{accountExecutive || '-'}</span>
    </div>
  </div>
);

export default SummaryInsightHeader;
