import { css } from 'emotion';

import { fontCaption1, fontHeading3 } from 'assets/css/fontStyles';

import { divider, ellipsis } from '../styles';

export { divider };

export const HeaderDetailsWrapper = css`
  display: flex;
  align-items: center;

  i {
    font-size: 16px;
    color: var(--bu-primary-700);
  }

  span {
    margin-left: 8px;
  }

  .detail {
    display: flex;
    align-items: center;

    span {
      ${ellipsis}
      max-width: 200px;
    }

    &.organizer {
      font-family: var(--bu-font-medium);
    }
  }
`;

export const callDetailsTooltipWrapper = css`
  max-height: 600px;
  overflow-x: auto;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 400px;

  .call-name {
    ${fontHeading3}
  }

  .call-start-date {
    margin-top: 8px;
    font-size: 13px;
    color: var(--bu-gray-700);
  }

  .call-details-wrapper {
    margin-top: 20px;

    .call-creator,
    .call-attendees,
    .call-description {
      display: grid;
      grid-template-columns: 1fr 6fr;
      grid-gap: var(--bu-control-gap-medium);
      margin-bottom: 8px;

      .item-name,
      .item-value {
        font-family: var(--bu-font-medium);
      }

      .item-name {
        justify-content: flex-end;
        text-align: right;
        color: var(--bu-gray-700);
      }

      .item-value {
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        color: var(--bu-gray-900);
        gap: var(--bu-control-gap-medium);
      }
    }

    .call-attendees .item-value {
      span {
        margin-left: 4px;
        font-size: 12px;
      }

      .accepted {
        color: var(--bu-green-500);
      }

      .declined {
        color: var(--bu-red-400);
      }

      .tentative {
        color: var(--bu-orange-500);
      }
    }
  }
`;

export const durationTooltipWrapper = css`
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);

  .duration-total {
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-small);
    font-family: var(--bu-font-medium);

    span {
      ${fontCaption1}
      color: var(--bu-gray-700);
    }
  }

  /* TODO: Will have this in the future */
  /* .duration-splitted {
    display: flex;
    align-items: center;
    gap: var(--bu-control-gap-medium);

    ::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: var(--bu-control-border-radius-medium);
    }

    &.customer {
      ::before {
        background-color: var(--bu-purple-500);
      }
    }

    &.company {
      ::before {
        background-color: var(--bu-lime-500);
      }
    }

    .duration-label {
      color: var(--bu-color-call-light-gray);
    }

    .duration-value,
    .duration-percentage {
      font-family: var(--bu-font-medium);
    }

    .duration-percentage {
      color: var(--bu-orange-500);
    }
  } */
`;

export const participantsListTooltipWrapper = css`
  max-height: 600px;
  overflow-x: auto;
  padding: 6px 10px;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .participant {
    display: flex;
    gap: var(--bu-control-gap-medium);

    .participant-info {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-small);
      font-family: var(--bu-font-medium);

      span + span {
        ${fontCaption1}
        color: var(--bu-gray-700);
      }
    }
  }
`;

export const removeTooltipBefore = css`
  margin: 0.5em 0 0 !important;

  ::before {
    content: none !important;
  }
`;
