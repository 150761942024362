import React from 'react';

import * as styles from './styles';
import MentionsCard from './MentionsCard';
import TopicsCompetitorsCard from './TopicsCompetitorsCard';
import SummaryInsightHeader from '../SummaryInsightHeader';
import {
  CallInsightsResponse,
  Mention,
  PositiveNegativeMention,
  TopicsCompetitors,
} from '../../types';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';
import { getSectionsToHighlight } from '../../helpers';

type Props = {
  opportunityName: string;
  accountExecutive: string;
  opportunityId?: string;
  positiveMentions: PositiveNegativeMention[];
  negativeMentions: PositiveNegativeMention[];
  topics: CallInsightsResponse['topics_discussed'];
  competitors: CallInsightsResponse['competitors_discussed'];
  segments: CallInsightsResponse['segments'];
};

const InsightTab: React.FC<Props> = ({
  opportunityName,
  accountExecutive,
  opportunityId,
  positiveMentions,
  negativeMentions,
  topics,
  competitors,
  segments,
}) => {
  const {
    highlighted,
    setHighlightedSections,
    setHighlighted,
    setTime,
    isVideoErrorOrLoading,
  } = useVideoContext();

  const handleClickInsightItem = (startTimes: number[]) => {
    setHighlightedSections(getSectionsToHighlight(startTimes, segments));
  };

  const handleClickTopicCompetitor = (
    item: TopicsCompetitors,
    type: 'topic' | 'competitor'
  ) => {
    if (highlighted.name === item.text) {
      handleClickInsightItem([]);
      setHighlighted({
        name: '',
        type: '',
      });
    } else {
      handleClickInsightItem(item.start_times);
      setHighlighted({
        name: item.text,
        type,
      });
    }
  };

  const handleClickMention = (mention: Mention) => {
    if (isVideoErrorOrLoading) return;
    setTime(mention.start_time);
  };

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.insightTabWrapper}>
        <SummaryInsightHeader
          opportunityName={opportunityName}
          accountExecutive={accountExecutive}
          opportunityId={opportunityId}
        />

        <TopicsCompetitorsCard
          data={competitors}
          type="competitor"
          handleClickTopicCompetitor={(item) =>
            handleClickTopicCompetitor(item, 'competitor')
          }
        />

        <TopicsCompetitorsCard
          data={topics}
          type="topic"
          handleClickTopicCompetitor={(item) =>
            handleClickTopicCompetitor(item, 'topic')
          }
        />

        {!!positiveMentions && (
          <MentionsCard
            data={positiveMentions}
            mentionType="positive"
            disabled={isVideoErrorOrLoading}
            handleClickMention={handleClickMention}
          />
        )}

        {!!negativeMentions && (
          <MentionsCard
            data={negativeMentions}
            mentionType="negative"
            disabled={isVideoErrorOrLoading}
            handleClickMention={handleClickMention}
          />
        )}
      </div>
    </div>
  );
};

export default InsightTab;
