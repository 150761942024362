import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import classNames from 'classnames';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';

import * as styles from './styles';
import { useVideoContext } from './videoPlayer.context';
import {
  formatTimeToFriendlyTimeString,
  getVideoTimeAsPercentage,
} from '../helpers';

const VideoPlayer: React.FC = () => {
  const {
    videoUrl,
    playbackRate,
    videoError,
    isLoading,
    isPlaying,
    currentTime,
    duration,
    videoElementRef,
    progressBarRef,
    progressDotRef,
    handlePlayPause,
    handleAdvance,
    handleRewind,
    handleFullScreen,
    handleProgressBarClick,
    handleMouseDown,
    handleError,
    handleCanPlay,
    handleDestroyComponent,
    handleClickIncreaseVideoPlaybackRate,
    handleClickDecreaseVideoPlaybackRate,
  } = useVideoContext();

  useEffect(() => handleDestroyComponent, []);

  const handleDoubleClickVideoPlayer = () => handleFullScreen();

  return (
    <div className={styles.callVideoPlayerWrapper}>
      <div
        className="video-player"
        onClick={handlePlayPause}
        onDoubleClick={handleDoubleClickVideoPlayer}
      >
        {isLoading && (
          <div className="video-loading">
            <Loader active={isLoading} />
          </div>
        )}

        {videoError ? (
          <div className="video-placeholder">
            <div>Call recording is not available for this meeting</div>
          </div>
        ) : (
          <video
            preload="auto"
            ref={videoElementRef}
            onCanPlay={handleCanPlay}
            onError={handleError}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {!videoError && (
        <>
          <div className="video-control">
            <div className="video-control-item playback-rate">
              <BuButton
                outline
                borderless
                onClick={handleClickDecreaseVideoPlaybackRate}
              >
                <BuIcon name={BoostUpIcons.ChevronLeft} />
              </BuButton>

              <span>{playbackRate}x</span>

              <BuButton
                outline
                borderless
                onClick={handleClickIncreaseVideoPlaybackRate}
              >
                <BuIcon name={BoostUpIcons.ChevronRight} />
              </BuButton>
            </div>

            <div className="video-control-item play">
              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleRewind}
                className="rewind-video"
              >
                <BuIcon name={BoostUpIcons.RefreshIcon} />
              </BuButton>

              <BuButton
                outline
                borderless
                onClick={handlePlayPause}
                className="play-button"
              >
                <BuIcon
                  name={
                    isPlaying ? BoostUpIcons.MediaPause : BoostUpIcons.MediaPlay
                  }
                />
              </BuButton>

              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleAdvance}
              >
                <BuIcon name={BoostUpIcons.RefreshIcon} />
              </BuButton>
            </div>

            <div className="video-control-item full-screen">
              <span>
                {formatTimeToFriendlyTimeString(currentTime)} /{' '}
                {formatTimeToFriendlyTimeString(duration)}
              </span>

              <BuButton
                outline
                borderless
                disabled={isLoading}
                onClick={handleFullScreen}
              >
                <BuIcon name={BoostUpIcons.FullScreen} />
              </BuButton>
            </div>
          </div>

          <div
            className={classNames('video-time', {
              disabled: isLoading,
            })}
            ref={progressBarRef}
            onClick={handleProgressBarClick}
          >
            <div
              className="video-time-progress"
              style={{
                width: `${getVideoTimeAsPercentage(currentTime, duration)}%`,
              }}
            />

            <TooltipWrapper
              tooltip={
                <div className={styles.textAlignCenter}>
                  {formatTimeToFriendlyTimeString(currentTime)}
                </div>
              }
              position="bottom center"
              mouseEnterDelay={0}
            >
              <div
                className={classNames('video-time-dot', {
                  disabled: isLoading,
                })}
                style={{
                  left: `calc(${getVideoTimeAsPercentage(
                    currentTime,
                    duration
                  )}% - 9px)`,
                }}
                ref={progressDotRef}
                onMouseDown={handleMouseDown}
              />
            </TooltipWrapper>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
