import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
  Size,
} from 'react-virtualized';

import BuToggle from 'components/UI/BuToggle';

import * as styles from './styles';
import SegmentCard from './SegmentCard';
import { useTranscriptContext } from './transcript.context';
import { Segment, Participant } from '../../types';
import { findNearestLowerStartTimeIndex } from '../../helpers';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';

type Props = {
  segments: Segment[];
  participants: Participant[];
};

const TranscriptTab: React.FC<Props> = ({ segments, participants }) => {
  const {
    scrollToIndex,
    isAutoscrollActive,
    setScrollToIndex,
    setIsAutoscrollActive,
  } = useTranscriptContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const segmentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const cellMeasurerCache = useRef(
    new CellMeasurerCache({
      defaultHeight: 100,
      fixedWidth: true,
    })
  );

  const { setTime, isVideoErrorOrLoading, currentTime } = useVideoContext();

  const handleClickSegmentCard = (index: number) => {
    if (isVideoErrorOrLoading) return;
    setTime(segmentsStartTime[index]);
  };

  const segmentsStartTime = useMemo(
    () => segments.map((segment) => segment.start_time),
    [segments]
  );

  const participantsColors = useMemo(
    () =>
      participants.reduce((acc, participant) => {
        acc[participant.name] = participant.color;
        return acc;
      }, {} as Record<string, string>),
    [participants]
  );

  const scrollIntoSegmentCard = useCallback(
    (elementId: number, forceScrollToIndex?: boolean) => {
      const segmentCardElem = segmentRefs.current[elementId];
      const container = containerRef.current;
      const transcriptListContainer = container?.querySelectorAll(
        '.ReactVirtualized__List'
      )[0];

      container
        ?.querySelectorAll('.segment-card.active')[0]
        ?.classList.remove('active');

      if (segmentCardElem && container) {
        segmentCardElem.classList.add('active');

        if (
          (isAutoscrollActive || forceScrollToIndex) &&
          transcriptListContainer
        ) {
          const containerTop =
            transcriptListContainer.getBoundingClientRect().top;
          const elementTop = segmentCardElem.getBoundingClientRect().top;
          const { scrollTop } = transcriptListContainer;
          const offset = elementTop - containerTop + scrollTop;

          transcriptListContainer.scrollTo({
            top: offset,
          });
        }
      }
    },
    [segmentRefs.current, containerRef.current, isAutoscrollActive]
  );

  const highlighSegment = (forceScrollToIndex?: boolean) => {
    const segmentCardIndex = findNearestLowerStartTimeIndex(
      segmentsStartTime,
      currentTime
    );

    if (segmentCardIndex) {
      // Immediately scrolling to have the elements rendered on the screen;
      if (isAutoscrollActive || forceScrollToIndex) {
        setScrollToIndex(segmentCardIndex);
      }

      // Deferring to ensure this will be called after the scroll to index;
      setTimeout(
        () => scrollIntoSegmentCard(segmentCardIndex, forceScrollToIndex),
        0
      );
    }
  };

  // Update selection upon access the tab;
  useEffect(() => highlighSegment(true), []);

  useEffect(
    () => highlighSegment(),
    [currentTime, segmentsStartTime, isAutoscrollActive]
  );

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const segment = segments[index];

    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache.current}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        <SegmentCard
          segment={segment}
          color={participantsColors[segment.speaker_name]}
          index={index}
          style={style}
          segmentRefs={segmentRefs}
          handleClickSegmentCard={handleClickSegmentCard}
        />
      </CellMeasurer>
    );
  };

  const handleAutoscrollChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIsAutoscrollActive(e.target.checked);

  return (
    <div
      className={classNames(
        styles.tabContentWrapper,
        'no-padding-top',
        'no-padding-right',
        'no-scroll'
      )}
      ref={containerRef}
    >
      {segments.length ? (
        <>
          <div className={styles.transcriptTabHeader}>
            <BuToggle
              leftLabel
              checked={isAutoscrollActive}
              onChange={handleAutoscrollChange}
            >
              Autoscroll
            </BuToggle>
          </div>

          <div
            className={classNames(styles.transcriptTabWrapper, {
              disabled: isVideoErrorOrLoading,
            })}
          >
            <AutoSizer>
              {({ height, width }: Size) => (
                <List
                  className={classNames({
                    'no-scroll': isAutoscrollActive,
                  })}
                  width={width}
                  height={height}
                  deferredMeasurementCache={cellMeasurerCache.current}
                  rowCount={segments.length}
                  rowHeight={cellMeasurerCache.current.rowHeight}
                  scrollToIndex={scrollToIndex}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </>
      ) : (
        <div className={styles.noTranscriptAvailable}>
          No transcript available
        </div>
      )}
    </div>
  );
};

export default TranscriptTab;
