import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { actions } from 'actions';
import { deletePartitionFromFieldsConfiguration } from 'actions/settingsActions';
import * as userProfileActions from 'actions/userProfilesActions';
import { TABLE_ID } from 'common/constants';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { table as s } from 'components/settings/UserProfiles/styles';
import { getColumnsConfig } from 'components/settings/UserProfiles/tableConfig';
import {
  IProps,
  UserProfile,
  UserProfileRequest,
} from 'components/settings/UserProfiles/types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { fetchApi } from 'utils/network';

export const profileBase = '/api/profile/profile';
export const apiUserProfile = `${process.env.REACT_APP_BACKEND_URL}${profileBase}`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const UserProfiles: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const [warnModal, setWarnModal] = useState<IRow | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [sort, setSort] = useState<string | undefined>();

  const { count } = useSelector(selectors.getUserProfilesState);
  const list: UserProfile[] = useSelector(
    selectors.getUserProfilesList({ search, sort })
  );

  useEffect(() => {
    dispatch(userProfileActions.getUserProfilesList());
  }, []);

  const warnModalTitle = useMemo(
    () =>
      warnModal
        ? `Are you sure you want to delete '${warnModal.name}' profile?`
        : '',
    [warnModal?.name]
  );

  const openEditModal = useCallback(
    ({ mode, row }: { mode: 'edit'; row?: IRow }) => {
      openModal({
        scheme: '/edit-profile/:profileId',
        params: { profileId: row?.id || '' },
        persistParams: {
          mode,
          row,
        },
        persistor: (params: PersistQueryParams) =>
          dispatch(actions.ui.modal.persist(params)),
      });
    },
    [dispatch]
  );

  const columns: IColumn[] = useMemo(
    () =>
      getColumnsConfig({
        deleteProfile: (row: IRow) => {
          setWarnModal(row);
        },
        editProfile: (row: IRow) => openEditModal({ mode: 'edit', row }),
      }),
    [setWarnModal, openEditModal]
  );

  const deleteUserProfile = useCallback((row: IRow) => {
    const abortController = new AbortController();

    fetchApi<UserProfileRequest, {}>({
      queryMethod: 'delete',
      setData: () => {
        dispatch(userProfileActions.getUserProfilesList());
        dispatch(
          deletePartitionFromFieldsConfiguration(`${profileBase}/${row.id}`)
        );
      },
      setError: (error: string | null) => {
        toast.error(`Deleting user profile failed: ${error}`, toastOptions);
      },
      signal: abortController.signal,
      url: `${apiUserProfile}/${row.id}`,
    });
  }, []);

  const handleAddNewProfile = useCallback(() => {
    dispatch(
      userProfileActions.createUserProfile({
        name: `New profile ${moment().format()}`,
        description: '',
        read_only: false,
        is_draft: true,
      })
    );
  }, []);

  const handleDeleteProfile = useCallback(() => {
    if (warnModal) {
      deleteUserProfile(warnModal);
      setWarnModal(null);
    }
  }, [warnModal && warnModal.id]);

  const handleCloseWarnModal = useCallback(() => {
    setWarnModal(null);
  }, []);

  return (
    <div className={s.container}>
      <Table
        tableId={TABLE_ID.USER_PROFILES}
        columns={columns}
        title="User Profiles"
        totalCount={count}
        data={list}
        rowsPerPage={50}
        currentPage={1}
        hidePaginationEnd
        hidePaginationStart
        searchPlaceholder="Search for user profile"
        onSearchChange={setSearch}
        sortOrder={sort}
        onSort={setSort}
        fullscreen
        hasActionsColumn
        controls={
          <BuButton size={BuControlSize.SMALL} onClick={handleAddNewProfile}>
            Add New
          </BuButton>
        }
      />

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        isOpen={!!warnModal}
        onClose={handleCloseWarnModal}
        onConfirm={handleDeleteProfile}
      >
        {warnModalTitle}
      </BuConfirmationPopup>
    </div>
  );
};

export default UserProfiles;
