import React from 'react';

import { Segment } from '../../types';
import RoundedAvatarIcon from '../../RoundedAvatarIcon';

type Props = {
  segment: Segment;
  color: string;
  index: number;
  style: React.CSSProperties;
  segmentRefs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>;
  handleClickSegmentCard: (startTime: number) => void;
};

const SegmentCard: React.FC<Props> = ({
  segment,
  color,
  index,
  style,
  segmentRefs,
  handleClickSegmentCard,
}) => {
  return (
    <div style={style}>
      <div
        className="segment-card"
        key={segment._id}
        onClick={() => handleClickSegmentCard(index)}
        ref={(el) => (segmentRefs.current[index] = el)}
      >
        <div className="active-border" style={{ borderColor: color }} />

        <RoundedAvatarIcon color={color} />

        <div className="message-info">
          <div className="message-info-name-time">
            <span>{segment.speaker_name}</span>
            <span>{segment.formattedStartTime}</span>
          </div>

          <p>{segment.transcript}</p>
        </div>
      </div>

      <div className="divider" />
    </div>
  );
};

export default SegmentCard;
