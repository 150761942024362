import axiosInstance from 'api/axiosInstance';
import { CallInsightsResponse } from 'components/dashboard/Calls/CallDetails/types';

type GetCallInsightsDataMethodParams = {
  callId: string;
};

export const getCallInsightsData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/`
  );

  return res.data.data;
};
