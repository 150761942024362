import styled from '@emotion/styled';

export const RoundedAvatar = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 50%;
  padding: 4px;
  height: 22px;
  width: 22px;

  background-color: ${(props) => props.backgroundColor};

  i {
    font-size: 16px;
    color: var(--bu-white);
  }
`;
