import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

import * as styles from './styles';
import { Attendee, CallInsightsResponse, Participant } from '../types';
import RoundedAvatarIcon from '../RoundedAvatarIcon';
import {
  ATTENDEE_RESPONSE_STATUS,
  formatDurationToFriendlyDurationString,
  replaceLinksWithAnchorTags,
} from '../helpers';

type Props = {
  attendees: Attendee[];
  participants: Participant[];
  title: string;
  duration: number;
  start: string;
  description: string;
  speakerPercentages: CallInsightsResponse['speaker_percentages'];
};

const HeaderDetails: React.FC<Props> = ({
  attendees,
  participants,
  title,
  duration,
  start,
  description,
  speakerPercentages,
}) => {
  const cleanHtmlDescription = replaceLinksWithAnchorTags(
    DOMPurify.sanitize(description)
  );

  const organizer = useMemo(
    () => attendees.find((attendee) => attendee.organizer),
    [attendees]
  );

  const attendeesCustomersFirst = useMemo(
    () =>
      attendees.slice().sort((a, b) => {
        if (a.is_customer && !b.is_customer) return -1;
        if (!a.is_customer && b.is_customer) return 1;
        return 0;
      }),
    [attendees]
  );

  const callStartDate = useMemo(
    () => moment(start).format('MMM D, YYYY [at] h.mma'),
    [start]
  );

  const participantsListForTooltip = useMemo(() => {
    const orderedParticipants = participants
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));

    if (organizer) {
      const organizerIndex = orderedParticipants.findIndex(
        (participant) => participant.name === organizer.unique_name
      );

      if (organizerIndex > -1) {
        const [organizer] = orderedParticipants.splice(organizerIndex, 1);
        orderedParticipants.unshift({
          ...organizer,
          organizer: true,
        });
      }
    }

    return orderedParticipants;
  }, [participants]);

  const getUniqueNameOrEmail = (attendee?: Attendee) =>
    attendee?.unique_name || attendee?.email;

  const callDateTooltip = (
    <div className={styles.callDetailsTooltipWrapper}>
      <div className="call-name">{title}</div>
      <div className="call-start-date">{callStartDate}</div>

      <div className="call-details-wrapper">
        <div className="call-creator">
          <div className="item-name">Creator:</div>

          <div className="item-value">{getUniqueNameOrEmail(organizer)}</div>
        </div>

        <div className="call-attendees">
          <div className="item-name">Attendees:</div>

          <div className="item-value">
            {attendeesCustomersFirst.map((attendee) => (
              <div key={getUniqueNameOrEmail(attendee)}>
                {getUniqueNameOrEmail(attendee)}

                <span className={attendee.response_status}>
                  ({ATTENDEE_RESPONSE_STATUS[attendee.response_status]})
                </span>
              </div>
            ))}
          </div>
        </div>

        {!!description && (
          <div className="call-description">
            <div className="item-name">Description:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: cleanHtmlDescription,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );

  const durationTooltip = (
    <div className={styles.durationTooltipWrapper}>
      <div className="duration-total">
        Total Duration: {formatDurationToFriendlyDurationString(duration)}
        {!!speakerPercentages && (
          <span>
            Speaking time:{' '}
            {formatDurationToFriendlyDurationString(
              speakerPercentages.speak.duration
            )}
          </span>
        )}
      </div>

      {/* TODO: Will have this in the future */}
      {/* <div className="duration-splitted customer">
        <span className="duration-label">Customer:</span>

        <span className="duration-value">
          {formatDurationToFriendlyDurationString(
            speakerTypePercentages.customer.duration
          )}
        </span>

        <span className="duration-percentage">
          ({speakerTypePercentages.customer.percentage.toFixed(2)}%)
        </span>
      </div>

      <div className="duration-splitted company">
        <span className="duration-label">Company:</span>

        <span className="duration-value">
          {formatDurationToFriendlyDurationString(
            speakerTypePercentages.company.duration
          )}
        </span>

        <span className="duration-percentage">
          ({speakerTypePercentages.company.percentage.toFixed(2)}%)
        </span>
      </div> */}
    </div>
  );

  const participantsTooltip = (
    <div className={styles.participantsListTooltipWrapper}>
      {participantsListForTooltip.map((participant) => (
        <div className="participant" key={participant.name}>
          <RoundedAvatarIcon color={participant.color} />

          <div className="participant-info">
            {!!participant.name && <span>{participant.name}</span>}
            {participant.organizer && <span>Organizer</span>}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.HeaderDetailsWrapper}>
      <TooltipWrapper
        tooltip={callDateTooltip}
        hoverable
        position="bottom left"
        popupClassName={styles.removeTooltipBefore}
      >
        <div className="detail">
          <BuIcon name={BoostUpIcons.Calendar} />
          <span>{callStartDate}</span>
        </div>
      </TooltipWrapper>

      <div className={classNames(styles.divider, 'minor')} />
      <TooltipWrapper
        tooltip={durationTooltip}
        hoverable
        position="bottom left"
        popupClassName={styles.removeTooltipBefore}
      >
        <div className="detail">
          <BuIcon name={BoostUpIcons.Clock} />
          <span>{formatDurationToFriendlyDurationString(duration, true)}</span>
        </div>
      </TooltipWrapper>

      <div className={classNames(styles.divider, 'minor')} />
      <TooltipWrapper
        tooltip={participantsListForTooltip.length && participantsTooltip}
        hoverable
        position="bottom left"
        popupClassName={styles.removeTooltipBefore}
      >
        <div className="detail">
          <BuIcon name={BoostUpIcons.UserGroup} />

          <span>
            {participants.length} Attendee{participants.length > 1 ? 's' : ''}
          </span>
        </div>
      </TooltipWrapper>

      {!!organizer && (
        <>
          <div className={classNames(styles.divider, 'minor')} />

          <div className="detail organizer">
            <BuIcon name={BoostUpIcons.User} />
            <span>{getUniqueNameOrEmail(organizer)}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderDetails;
